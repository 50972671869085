import { createRouter, createWebHistory } from 'vue-router'
import { useModalRouter } from "jenesius-vue-modal";
import { teamroutes } from '@/router/teamroutes.js';
import HelloView from '../views/onboarding/HelloView.vue'
import { useUserStore } from "@/stores/userStore";
import { request } from "@/api";
import { useTeamStore } from "@/stores/teamStore";
import { useProjectStore } from "@/stores/projectStore";
import { getProjectSingle } from "@/api/projectsApi.js";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HelloView
    },
    {
      path: '/:team',
      name: 'team',
      component: () => import('../views/TeamShell.vue'),
      children: teamroutes
    },
    {
      path: '/shared/:team/projects/:slug/:token',
      name: 'projectClient',
      component: () => import('../views/projects/ProjectClientView.vue')
    },
  ]
})

useModalRouter.init(router);

router.beforeEach(async(to, from) => {
  // If on Shared Route:
  if (to.name === 'projectClient') {
    // console.log( "init shared project page...");
    return true;
  }

  // Check if Team Route needs to fetch initial Data...
  if ( to.params.team ) 
  {
      const teamStore = useTeamStore();
      const projectStore = useProjectStore();
      const userStore = useUserStore();
      if ( teamStore.hasData() ) return true;
      let prefix = from.name == "home" ? to.params.team + '/' : '';
      const { data, error } = await request( prefix + "init");
      if (!data) return false;
      teamStore.setApiTeam(data.team);
      projectStore.setApiProjects(data.projects);
      userStore.store(data.user);
      if ( to.path.indexOf('projects') !== -1 && to.params.id ) {
        await getProjectSingle(to.params.id);
      }
  }
  // return false
})

export default router
