import TodoSingleModal from "@/components/todos/TodoSingleModal.vue";

export const teamroutes = [
	{
		path: '',
		name: 'dashboard',
		component: () => import('../views/team/DashboardView.vue'),
    children : [
        {
            path: "todo/:todoid",
            name: "ToDoModalDashboard",
            component: () => import('../views/team/DashboardView.vue'),
        }
    ]
	},
	{
		path: 'team',
		name: 'teamView',
		component: () => import('../views/team/TeamView.vue')
	},
    {
      path: 'projects',
      name: 'projects',
      component: () => import('../views/projects/ProjectsView.vue')
    },
    {
      path: 'projects/:slug',
      name: 'projectSingle',
      component: () => import('../views/projects/ProjectSingleView.vue'),
    },
    {
      path: 'projects/:slug/to-dos',
      name: 'projectToDos',
      // component: () => import('../views/projects/ProjectToDosView.vue'),
      component: () => import('../views/projects/ProjectSingleView.vue'),
      // Route Modal Beispiel...
      children : [
          {
              path: ":todoid",
              name: "ToDoModal",
              component: () => import('../views/projects/ProjectSingleView.vue'),
          }
      ]
    },
    {
      path: 'projects/:slug/time-tracking',
      name: 'projectTimeTracking',
      component: () => import('../views/projects/ProjectTimeTrackingView.vue')
    },
    {
      path: 'projects/:slug/clientPageEditor',
      name: 'clientPageEditor',
      component: () => import('../views/projects/ProjectClientPageEditor.vue')
    },
    {
      path: 'projects/:slug/settings',
      name: 'projectSettings',
      component: () => import('../views/projects/ProjectSettingsView.vue')
    },
    {
      path: 'clients',
      name: 'clients',
      component: () => import('../views/clients/ClientsView.vue')
    },
    {
      path: 'clients/:slug',
      name: 'clientSingle',
      component: () => import('../views/clients/ClientSingle.vue')
    },
    {
      path: 'time-tracking',
      name: 'time-tracking',
      component: () => import('../views/timetracking/TrackingView.vue')
    },
	 {
      path: 'profile',
      name: 'profile',
      component: () => import('../views/user/ProfileView.vue')
    }
]