<script setup>
import { useRouter } from "vue-router";
import { login } from "@/api/authApi.js";
import { useUserStore } from "@/stores/userStore";
import { useConfirm } from "primevue/useconfirm";
import Password from "primevue/password";
import InputText from "primevue/inputtext";

const email = ref(null);
const password = ref(null);
const router = useRouter();
const user = useUserStore();
const confirm = useConfirm();
const loading = ref(false);

const loginSubmit = async () => {
    loading.value = true;
    const response = await login(email.value, password.value);
    loading.value = false;
    if (response !== true) {
        return confirm.require({
            message: "Zugangsdaten stimmen nicht.",
            header: "Whoopsie!",
            icon: "pi pi-exclamation-triangle",
            rejectLabel: null,
            acceptLabel: "Verstanden",
            rejectClass: "hidden",
            accept: () => {
                //callback to execute when user confirms the action
            },
            reject: () => {
                //callback to execute when user rejects the action
            },
        });
    }
    // Check if only one Team
    if (user.teams.length === 1) {
        router.push({ name: "teamView", params: { team: user.teams[0].slug } });
    } else {
        alert("TODO: Catch more or less than one team...");
    }
};
</script>

<template>
    <form @submit.prevent="loginSubmit">
        <div class="input_wrapper">
            <label>E-Mail</label>
            <InputText type="text" v-model="email" autocomplete="name e-mail" />
        </div>
        <div class="input_wrapper">
            <label>Password</label>
            <Password v-model="password" autocomplete="password" :feedback="false" :toggleMask="true" />
        </div>
        <div class="align-right">
            <Button type="submit" label="Login" :loading="loading" />
        </div>
    </form>
</template>

<style lang="scss" scoped></style>
