import { request } from "@/api";
import { useProjectStore } from "@/stores/projectStore";
import { useUserStore } from "@/stores/userStore";
import debounce from "lodash.debounce";

/*
 *  POST /todos
 */
export const requestAddTodo = async (params) => {
	const { data, error } = await request("todos", "post", params);
	if ( ! data ) return error;
	return data;
}

/*
 *  GET /todos/id
 */
export const getTodo = async (id) => {
	const { data, error } = await request("todos/" + id, "get");
	if ( ! data ) return error;
	return data;
}

/*
 *		UPDATE /todos
 */
 export const requestUpdateTodo = async (params) => {
	return debounceUpdateTodo(params);			// Mit Debounce Request weiter machen
}

const debounceUpdateTodo = debounce(async(params) => {
	const { data, error } = await request("todos/" + params.id, "put", params.data);
	if ( ! data ) return error;
	// Todo bei Error ggf. notify bzw Todo resetten, was vorher schon in den Store gepusht wurde 
}, 200)

/*
 *  DELETE /todos
 */
export const deleteTodo = async (id) => {
	const store = useProjectStore();
	const { data, error } = await request("todos/" + id, "delete");
	if ( ! data ) return error;
	store.removeTodoList(id);
	return data;
}

/*
 *	 Re-Order Todos
 */

 export const reorderTodos = async (params) => {
	return debounceReOrderList(params);
}

const debounceReOrderList = debounce(async(params) => {
	const { data, error } = await request('todos/reOrder', "put", { todos: params.todos});
	if ( ! data ) return;
	return data;
}, 200)