import { defineStore } from 'pinia'

export const useUserStore = defineStore('User', {

	persist: false,

	state: () => ({
		id: '',
		name: '',
		avatar: '',
		dynamic_list: false,
		personal_list: false,
		settings: [],
	}),

	getters: {
	},

	actions: {
		store(user) {
			for (const [key, value] of Object.entries(user)) {
				this[key] = value;
			 }
		},
		setDynamicTodoList(list) {
			this.dynamic_list = list;
		},
		findTodoInDynamicList(id) {
			for ( var i = 0; i < this.dynamic_list.todos.length; i++) {
				if ( this.dynamic_list.todos[i].id == id ) return this.dynamic_list.todos[i];
			}
			return false;
		},
		setDynamicTodoValue(todo, obj) {
			let newtodo = this.findTodoInDynamicList(todo.id);
			if ( ! newtodo ) return;
			for (const [key, value] of Object.entries(obj)) {
				newtodo[key] = value;
			}
			newtodo.isLoading = false;
		},
  	},
})