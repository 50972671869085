<script setup>
import { RouterView } from "vue-router";
import ConfirmDialog from "primevue/confirmdialog";
import Toast from "primevue/toast";
import { container } from "jenesius-vue-modal";

const WidgetContainerModal = container;
</script>

<template>
    <Suspense>
        <RouterView />
    </Suspense>
    <ConfirmDialog></ConfirmDialog>
    <Toast position="top-right" />
    <widget-container-modal />
</template>

<style lang="scss">
@import "@/assets/scss/_boilerplate.scss";
</style>
