import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import './assets/style.css' // muss vor primeVue kommen, da tailwind einige styles sonst resetted

import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from './router'

import mitt from 'mitt';
window.bus = mitt();

const app = createApp(App)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(router)
app.use(PrimeVue);

// PrimeVue Components
import 'primeicons/primeicons.css';

import ConfirmationService from 'primevue/confirmationservice';
app.use(ConfirmationService);
import ToastService from 'primevue/toastservice';
app.use(ToastService);
import Tooltip from 'primevue/tooltip';
app.directive('tooltip', Tooltip);
// import Dialog from 'primevue/dialog';
// app.component('Dialog', Dialog);
// import Button from 'primevue/button';
// app.component('Button', Button);
// import Dropdown from 'primevue/dropdown';
// app.component('Dropdown', Dropdown);

app.mount('#app')
