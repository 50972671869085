import { defineStore } from 'pinia';
import memoize from 'fast-memoize';

// Memoize Helper
// const cachedUserById = memoize((store, id) => {
// 	for (var i = 0; i < store['users'].length; i++) {
// 		if ( store['users'][i].id == id)   return store['users'][i];
// 	}
// });

// TODO Cache System wieder einführen. Memoize macht im Prod-Build zicken :-(
const cachedUserById = (store, id) => {
	for (var i = 0; i < store['users'].length; i++) {
		if ( store['users'][i].id == id)   return store['users'][i];
	}
};


export const useTeamStore = defineStore('Team', {

	persist: false,

	state: () => ({
		name: '',
		id: false,
		slug: '',
		logo: false,
		users: []
	}),

	getters: {
		getUserById: (state) => {
			return (id) => cachedUserById(state, id);
		},
	},

	actions: {
		hasData() {
			if ( this.users.length == 0 ) return false;
			return true;
		},
		setApiTeam(team) {
			for (const [key, value] of Object.entries(team)) {
				this[key] = value;
			 }
		},
		setUserData(user_id, data) {
			let user = this.getUserById(user_id);
			if ( ! user ) return;
			for (const [key, value] of Object.entries(data)) {
				user[key] = value;
			}
		},
		addUser(user) {
			this.users.push(user);
		}
  	},
})