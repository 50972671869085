import { api, request } from "@/api";
import { useTeamStore } from "@/stores/teamStore";
import { useUserStore } from "@/stores/userStore";


/*
 *  POST /sanctum/token
 */
export const login = async (email, password) => {
	const store = useUserStore();
	const { data, apierror } = await request("sanctum/token", "post", { email: email, password: password }, false );
	if ( ! data ) return false;
	localStorage.setItem('token', data.token)
	store.store(data.user);
	return true;
}

export const logout = () => {
	const store = useUserStore();
	localStorage.removeItem('token');
	store.store({});
	return true;
}

/*
 *  POST user/savepass
 */

export const changePass = async (params) => {
	const { data, apierror } = await request("user/savepass", "post", params, false );
	if ( ! data ) return apierror;
	return true;
}