import { defineStore } from 'pinia'

export const useProjectStore = defineStore('Projects', {

	persist: false,

	state: () => ({
		list: [],
	}),

	getters: {
		getProjects: (state) => {
			return () => state.list;
		},
		getProjectById: (state) => {
			return (id) => state.list.find((project) => project.id === id)
		},
		getProjectBySlug: (state) => {
			return (slug) => state.list.find((project) => project.slug === slug)
		},
		getProjectByName: (state) => {
			return (name) => state.list.find((project) => project.name === name)
		},
	},

	actions: {
		setApiProjects(projects) {
			this.list = projects;
		},
		setApiProjectSingle(project) {
			for ( var i = 0; i < this.list.length; i++ ) {
				if ( this.list[i].id !== project.id ) continue;
				this.list[i] = project;
				return;
			}
			this.list.push(project); // Noch nicht in Liste...
		},
		addProject(data) {
			return this.list.unshift(data);
		},
		updateProject(project, data) {
			let item = this.getProjectById(project.id);
			for (const [key, value] of Object.entries(data)) {
				item[key] = value;
			}
		},
		deleteProject(project) {
			this.list = this.list.filter((item) => item.id !== project.id);
		},
		addTodoListToProject(project_id, list) {
			let project = this.getProjectById(project_id);
			project.todo_lists = [
				...project.todo_lists,
				list
			];
		},
		removeTodoList(list_id) {
			for ( var i = 0; i < this.list.length; i++ ) {
				if ( typeof this.list[i].todo_lists == 'undefined' ) continue;
				for ( var y = 0; y < this.list[i].todo_lists.length; y++ ) {
					if ( this.list[i].todo_lists[y].id !== list_id ) continue;
					this.list[i].todo_lists.splice(y, 1);
				}
			}
		},
		reOrderLists(lists, project_slug) {
			for (var i = 0; i < this.list.length; i++) {
				if ( this.list[i].slug == project_slug) {
					this.list[i].todo_lists = [ ...lists ];
				}
			}
		},
		assignUser(item, user) {
			item.users.push(user)
		},
		updateAssignedUser(item, user) {
			for ( var i = 0; i < item.users.length; i++ ) {
				if ( item.users[i].id == user.user_id ) {
					item.users[i] = user;
				}
			 }
		},
		removeUser(user_id, item) {
			let index = 0;
			for ( var i = 0; i < item.users.length; i++ ) {
			  if ( item.users[i].user_id == user_id ) index = i;
			}
			item.users.splice(index, 1);
		}
  	},
})