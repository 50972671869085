import { request } from "@/api";
import { useProjectStore } from "@/stores/projectStore";
import debounce from "lodash.debounce";

/*
 *  GET /projects
 */
export const getProjects = async () => {
	const store = useProjectStore();
	const { data, error } = await request("projects");
	if ( ! data ) return;
	store.setApiProjects(data);
}

/*
 *  POST /projects
 */
export const newProject = async (project) => {
	const store = useProjectStore();
	const { data, error } = await request("projects", "post", project);
	if ( ! data ) return;
	store.addProject(data);
	return data;
}

/*
 *  GET /projects/:slug
 */

export const getProjectSingle = async (project_slug) => {
	const store = useProjectStore();
	const { data, error } = await request("projects/" + project_slug);
	if ( ! data ) return false;
	store.setApiProjectSingle(data)
	return data;
}

/*
 *		UPDATE /projects/:slug
 */
 export const updateProject = async (project, params) => {
	const store = useProjectStore();
	store.updateProject(project, params); 							// Vorher schon in den Store pushen, sonst wg Debounce ggf. Wonky
	const { data, error } = await request("projects/" + project.slug, "put", params);
	if ( ! data ) return error;
	return data;
}

/*
 *  DELETE /projects/:slug
 */
export const deleteProject = async (slug) => {
	const { data, error } = await request("projects/" + slug, 'delete');
	if ( ! data ) return;
	return data;
}

/*
 *	 Re-Order Todo-Lists
 */

 export const reorderTodoLists = async (params) => {
	return debounceReOrderTodoList(params);
}

const debounceReOrderTodoList = debounce(async(params) => {
	const { data, error } = await request('todolists/reOrder', "put", { todolists: params.todolists});
	if ( ! data ) return;
	return data;
}, 200)

/*
 *  POST /projectusers
 */
export const addUserToProject = async (params) => {
	const { data, error } = await request("projectusers", 'post', params);
	if ( ! data ) return;
	return data;
}

/*
 *  UPDATE /projectusers/:id
 */
export const updateProjectUser = async (id, input) => {
	const { data, error } = await request("projectusers/" + id, 'put', input);
	if ( ! data ) return;
	return data;
}

/*
 *  UPDATE a Data Property /projectusers/:id/data
 */
export const updateProjectUserData = async (id, params) => {
	const { data, error } = await request("projectusers/" + id + "/data", 'put', params);
	if ( ! data ) return;
	return data;
}

/*
 *  DELETE /projectusers/:id
 */
// export const deleteProjectUser = async (id) => {
// 	const { data, error } = await request("projectusers/" + id, 'delete');
// 	if ( ! data ) return;
// 	return data;
// }

