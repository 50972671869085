import { format } from "date-fns";
 
 // Stub Model for instant Insert, before Request resolves...
 export const todoModel = {
	start: format(new Date(), "yyyy-MM-dd"),
	end: format(new Date().setDate(new Date().getDate() + 7), "yyyy-MM-dd"),
	progress: 0,
	finished: 0,
	parent_id: null,
	description: null,
	color: null,
	users: [],
	sub_todos: [],
	show_sub_todos: false,
}
