import { request } from "@/api";
import { useProjectStore } from "@/stores/projectStore";
import { useUserStore } from "@/stores/userStore";
import debounce from "lodash.debounce";

/*
 *  POST /todolist
 */
export const addList = async (params) => {
	const store = useProjectStore();
	const { data, error } = await request("todolists", "post", params);
	if ( ! data ) return;
	if ( params.project_id ) {
		store.addTodoListToProject(params.project_id, data);
	}
	return data;
}

/*
 *  GET /todolist/dynamic
 */
export const updateDynamicList = async(params) => {
	const { data, error } = await request("todolists/dynamic", "post", params);
	if ( ! data ) return;
	const store = useUserStore();
	store.setDynamicTodoList(data);
	return data;
};

/*
 *	Update TodoList
 */

export const requestUpdateTodoList = async (params) => {
	return debounceUpdateTodoList(params);
}

const debounceUpdateTodoList = debounce(async(params) => {
	const { data, error } = await request("todolists/" + params.list, "put", params.data);
	if ( ! data ) return error;
	return data;
}, 200);

/*
 *  DELETE /todolist
 */
export const removeList = async (id) => {
	const store = useProjectStore();
	const { data, error } = await request("todolists/" + id, "delete");
	if ( ! data ) return;
	store.removeTodoList(id);
	return data;
}