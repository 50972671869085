import Axios from 'axios'

export const api = Axios.create({
	withCredentials: false,
	headers: { 
		Accept: 'application/json',
	}
});

/*
 *		General Requests
 */

export const request = async(url, method = 'get', params = {}, teamBaseUrl = true ) => {

	let data = null;
	let apierror = null;

	const controller = new AbortController();

	let baseURL = import.meta.env.VITE_API_BASE;
	let team = window.location.pathname.split('/');
	
	if ( teamBaseUrl && typeof team[1] !== 'undefined' ) {
		baseURL = baseURL + team[1];
	}

	await api({
		method: method,
		url: url,
		baseURL: baseURL,
		data: params,
		signal: controller.signal,
		headers: { 
			Authorization: `Bearer ` + localStorage.getItem('token')
		}
	}).then(function (response) {
		console.log("=== ["+method.toUpperCase()+"] : "+baseURL+"/"+url+" ===")
		console.log( response );
		data = response.data;
	})
	.catch(function (error) {
		console.log(error.response);
		apierror = error.response.data.message;
	})
	.then(function() {
		// always executed
	});  

	// cancel the request
	// controller.abort()

	return { data, apierror }
}